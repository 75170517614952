<template>
    <div class="m_creator_container">
        <div class="creator_cont">
            <div class="header">
                <img src="@/assets/image/m_creator/m_creator_title.png" alt="" class="title">
                <img src="@/assets/image/m_creator/m_jiantou.png" class="jiantou">
            </div>
            <section class="section">
                <!-- 创作者作品展示 -->
                <div class="creator_work_cont">
                    <img src="@/assets/image/m_creator/work_title.png" class="work-title">
                    <img src="@/assets/image/m_creator/playlist_logo.png" class="logo">
                    <div class="work_detail">
                        <div class="work_video" v-for="(item, index) in workList" :key="index">
                            <div class="work_video_desc">
                                <video ref="videoPlayer" id="video" :poster="item.workurl + '?' + 'vframe/jpg/offset/' + index + 8"
                                    width="236px" height="157px">
                                    <source :src="item.workurl" />
                                </video>
                                <div class="play_mask">
                                    <img src="@/assets/image/m_creator/m_playbtn.png" class="play"
                                        @click="playWork(item.workurl)">
                                </div>
                            </div>
                            <div class="creator_name">
                                <span>创作者：{{ item.creator }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="gift_explain">
                        <ul>
                            <li>即日起，<span>抖音、快手、B站、小红书</span>等平台视频博主，发布带有PlayList口播、展示LOGO及网址的视频，选中即可获得三重好礼！</li>
                            <li>一重礼：PlayList会员直接<label>免费</label>赠送</li>
                            <li>二重礼：视频播放量达到规定数量，直接获赠<label>现金</label></li>
                            <li>三重礼：粉丝通过专属邀请码购买Playlist任意会员，再赠送博主<label>现金</label></li>
                        </ul>
                    </div>
                    <div class="active_mode">
                        <label>客服微信：</label>
                        <div class="erweima">
                            <img src="@/assets/image/creator_img/kefu_erweima.png" alt="">
                        </div>
                        <p class="email"><span>活动投稿邮箱：</span>{{ email }}<img src="@/assets/image/m_creator/m_copy.png"
                                class="copy" :data-clipboard-text="email" @click="copy"></p>
                    </div>
                </div>
                <!-- 活动奖励 -->
                <div class="active_reward">
                    <img src="@/assets/image/m_creator/m_reward.png" alt="">
                </div>
                <!-- 活动规则 -->
                <div class="active_rules">
                    <img src="@/assets/image/m_creator/rules_title.png" class="work-title">
                    <img src="@/assets/image/m_creator/playlist_logo.png" class="logo">
                    <ul>
                        <li>1、本活动面向抖音、快手、B站、小红书等视频平台博主；</li>
                        <li>2、博主视频被选中，将有机会获得三重奖励：<br> 一重礼：获赠PlayList会员；<br> 二重礼：视频播放量达到规定数量，获赠现金； <br>三重礼：粉丝通过专属邀请码购买PlayList会员后获得现金回馈；</li>
                        <li>3、视频要求悬挂90天；</li>
                        <li>4、活动发送的会员及现金，与客服微信联系后完成奖励发放；</li>
                        <li>5、视频互动数据（点赞收藏转发评论）统计截止时间：视频发布之日起，一个自然月的数据累计；</li>
                        <li>6、本活动最终解释权归北京数音未来科技有限公司所有。</li>
                    </ul>
                </div>
                <div class="playlist_confirm">
                    <img src="@/assets/image/m_creator/m_footer.png" alt="">
                </div>
            </section>
        </div>

        <!-- 作品详情介绍弹窗 -->
        <div class="work_dialog" v-show="dialogVisiblework">
            <div class="active_dialog_detail">
                <div class="active__dialog_video">
                    <!-- <div class="mask_cont" v-show="showMask">
                        <img src="@/assets/image/play.png" @click="playVideo">
                    </div> -->
                    <img src="@/assets/image/close_video.png" class="close_video" @click="closeVideodialog">
                    <video ref="video" :src="videoUrl" :poster="poster" id="work_video" width="100%" height="220.8px"
                        controls autoplay="autoplay" disablepictureinpicture playsInline={true}>
                        <!-- <source :src="videoUrl" /> -->
                    </video>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Clipboard from 'clipboard';
export default {
    data() {
        return {
            workList: [
                { workurl: 'https://qkodo.playlistmusic.com.cn/default%2FB%E7%AB%99UPLEO%E5%8F%94%E5%8F%94%E7%88%B1%E6%91%84%E5%BD%B1-%E7%94%A8%E5%A4%B4%E5%83%8F%E5%81%9A%E4%B8%80%E4%B8%AA%E5%B1%9E%E4%BA%8E%E8%87%AA%E5%B7%B1%E7%9A%84%E5%8A%A8%E7%94%BB%E7%89%87%E5%A4%B4_1.mp4', creator: 'Leo叔叔爱摄影' },
                { workurl: 'https://qkodo.playlistmusic.com.cn/default%2FB%E7%AB%99UPLEO%E5%8F%94%E5%8F%94%E7%88%B1%E6%91%84%E5%BD%B1-PR45-%E6%96%87%E5%AD%97%E7%BA%BF%E6%9D%A1logo%E5%8A%A8%E7%94%BB%E7%89%87%E5%A4%B4_1.mp4', creator: 'Leo叔叔爱摄影' },
                { workurl: 'https://qkodo.playlistmusic.com.cn/default%2FB%E7%AB%99UP%E9%99%88%E4%BA%8C%E7%8B%97chairgo%E4%BB%8B%E7%BB%8D%E8%A7%86%E9%A2%91_1.mp4', creator: '陈二狗chairgo' },
                { workurl: 'https://qkodo.playlistmusic.com.cn/default%2FB%E7%AB%99UP%E5%8D%A2%E5%A7%A5%E7%88%B7Laoye%20pr-%E6%B0%B4%E6%B3%A2%E7%BA%B9%E5%AD%97%E5%B9%95-%E5%89%AA%E8%BE%91%E7%89%87_1.mp4', creator: '卢姥爷Laoye' }
            ],
            videoUrl: '',
            dialogVisiblework: false,
            poster: require('@/assets/image/poster_video.jpg'),
            email: 'huodong@playlistmusic.com.cn' //投稿邮箱
        }
    },
    methods: {
        /**
         * 打开视频弹框播放对应视频
         */
        playWork(workurl) {
            this.dialogVisiblework = true;
            this.videoUrl = workurl;
            this.$refs.video.play()
            // .catch(() => {
            //     this.$refs.video.pause()
            // })
            // this.$refs.video.play();
        },
        closeVideodialog() {
            this.dialogVisiblework = false;
            // this.videoUrl = "";
            this.$refs.video.pause();
        },
        /**
         * 复制邮箱到剪切板
         */
        copy() {
            var clipboard = new Clipboard('.copy')
            clipboard.on('success', () => {
                // alert("已成功复制到剪切板")
                this.$message.success('已成功复制到剪切板')
                clipboard.destroy()// 释放内存
            })
            clipboard.on('error', () => {
                this.$message.warning('不支持复制,该浏览器不支持自动复制！')
                clipboard.destroy()// 释放内存
            })
        }
    }
}
</script>

<style lang="scss" scoped>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.m_creator_container {
    width: 100%;
    // min-height: 38rem;
    background: url('~@/assets/image/m_creator/m_creator_bg.jpg') no-repeat;
    background-size: 100% 100%;
    padding-bottom: 2.13rem;

    .creator_cont {
        width: 100%;

        .header {
            width: 100%;
            text-align: center;
            padding-top: 2.1rem;

            .title {
                width: 6.12rem;
                margin: 0 auto;
                display: block;
            }

            .jiantou {
                width: .77rem;
                padding-top: .5rem;
            }
        }

        .section {
            width: 100%;
            height: auto;
            padding: 0rem .32rem;

            .creator_work_cont {
                margin-top: .7rem;
                width: 100%;
                height: auto;
                background: url('~@/assets/image/m_creator/kuang_bg.png') no-repeat;
                background-size: 100% 100%;
                padding: .6rem .8rem .54rem .8rem;
                position: relative;

                .work-title {
                    width: 2.95rem;
                    position: absolute;
                    left: 50%;
                    margin-left: -1.475rem;
                    top: -.5rem
                }

                .logo {
                    position: absolute;
                    width: 1.54rem;
                    bottom: -.15rem;
                    right: .6rem;
                }

                .work_detail {
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    justify-content: space-between;

                    .work_video {
                        width: 2.6rem;
                        height: 2.41rem;
                        background: url('~@/assets/image/m_creator/m_workbg.png') no-repeat;
                        background-size: 100% 100%;
                        padding: .43rem .34rem .3rem .27rem;
                        display: flex;
                        flex-direction: column;

                        .work_video_desc {
                            width: 100%;
                            height: 1.08rem;
                            background: url('~@/assets/image/m_creator/m_videobg.png') no-repeat;
                            background-size: 100% 100%;
                            position: relative;

                            #video {
                                width: 100%;
                                height: 1.08rem;
                                object-fit: fill;
                            }

                            .play_mask {
                                width: 100%;
                                height: 1.08rem;
                                position: absolute;
                                left: 0;
                                top: 0;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                .play {
                                    width: .5rem;
                                }
                            }
                        }

                        .creator_name {
                            width: 100%;
                            height: .35rem;
                            padding: 0rem 0.05rem;
                            display: flex;
                            align-items: center;
                            background: #FFC5BA;
                            border-radius: 0.05rem;
                            margin-top: .1rem;

                            span {
                                font-size: .16rem;
                                font-family: MaoKenTangYuan (beta);
                                font-weight: 400;
                                color: #FFFFFF;
                                text-shadow: 0rem 0.02rem 0.03rem rgba(170, 8, 8, 0.76);
                            }
                        }
                    }
                }

                .gift_explain {
                    width: 100%;

                    ul {
                        li {
                            font-size: .24rem;
                            font-family: MaoKenTangYuan;
                            font-weight: 400;
                            color: #FFFFFF;
                            line-height: .4rem;
                            text-shadow: 0rem 0.02rem 0.03rem rgba(0, 0, 0, 0.76);

                            span {
                                color: #FF2727;
                            }

                            label {
                                font-size: .26rem;
                                color: #FFFF00;
                            }
                        }
                    }
                }

                .active_mode {
                    // width: 4rem;
                    margin: 0 auto;
                    text-align: center;

                    label {
                        display: block;
                        padding-top: .22rem;
                        padding-left: 1.04rem;
                        text-align: left;
                        font-size: .26rem;
                        font-family: shetumodengxiaofangti;
                        font-weight: 400;
                        color: #FFFFFF;
                        text-shadow: 0rem 0.02rem 0.03rem rgba(0, 0, 0, 0.76);
                    }

                    .erweima {
                        width: 1.2rem;
                        margin: .2rem auto 0rem;

                        img {
                            width: 100%;
                        }
                    }

                    .email {
                        font-size: .22rem;
                        font-family: MaoKenTangYuan (beta);
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 23px;
                        text-shadow: 0px 3px 7px rgba(0, 0, 0, 0.76);
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        span {
                            color: #FFFF00;
                        }

                        .copy {
                            width: .25rem;
                            margin-left: .1rem;
                        }
                    }
                }
            }

            .active_reward {
                width: 100%;
                margin-top: .44rem;

                img {
                    width: 100%;
                }
            }

            // 活动规则
            .active_rules {
                width: 100%;
                height: auto;
                background: url('~@/assets/image/m_creator/kuang_bg.png') no-repeat;
                background-size: 100% 100%;
                padding: .7rem .5rem .86rem .7rem;
                margin-top: .84rem;
                position: relative;

                .work-title {
                    width: 2.95rem;
                    position: absolute;
                    left: 50%;
                    margin-left: -1.475rem;
                    top: -.5rem
                }

                .logo {
                    position: absolute;
                    width: 1.54rem;
                    bottom: -.15rem;
                    right: .6rem;
                }

                ul {
                    li {
                        font-size: .24rem;
                        font-family: MaoKenTangYuan;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: .45rem;
                        text-shadow: 0rem 0.02rem 0.01rem rgba(3, 70, 153, 0.51);
                    }
                }
            }

            .playlist_confirm {
                width: 5.17rem;
                margin: .5rem auto 0rem;

                img {
                    width: 100%;
                }
            }
        }
    }

    /**
    活动介绍
    */
    .work_dialog {
        z-index: 2000;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .5);

        .active_dialog_detail {
            width: 100%;
            height: 100%;
            @include center-middle-flex;

            .active__dialog_video {
                width: 85%;
                height: 4rem;
                position: relative;

                #work_video {
                    object-fit: fill;
                }

                .close_video {
                    z-index: 3000;
                    position: absolute;
                    width: .7rem;
                    position: absolute;
                    right: -.1rem;
                    top: -.9rem;
                    cursor: pointer;
                }
            }
        }
    }
}</style>
